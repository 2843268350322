<template>
  <div :class="propsClassName">

    <div class="form-groupchk radiobox" v-for="(department, inx) in departments" :key="inx">
      <input type="checkbox" :id="`defaultCheck${inx}`" @change="() => changeSelectedDepartments(department)" :checked="selectedList.includes(department)">
      <label :for="`defaultCheck${inx}`">{{$t(`sabre.search-panel.${department}`)}}</label>
    </div>

    <div class="btn-group-area d-flex justify-content-center" v-if="type==='dropdown'">
      <button class="save_btn" @click="saveStore">{{$t("sabre.buttons.save")}}</button>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'select-department',
  props: {
    type: {
      type: String,
      default: '',
    },
    propsClassName: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters({
      selectedList: 'GET_SABRE_SELECTED_FLIGHT_DEPARTMENTS',
      departments: 'GET_SABRE_FLIGHT_DEPARTMENT_LIST',
    }),
  },
  data() {
    return {
      selectedDepartments: [],
    };
  },
  mounted() {
    if (!this.selectedList?.length) { this.setStore(this.departments[0]); }
  },
  methods: {
    changeSelectedDepartments(one) {
      this.setStore(one);
    },
    saveStore() {
      this.$emit('finished');
    },
    setStore(one) {
      this.$store.commit('SET_SABRE_FLIGHT_DEPARTMENT', { item: one });
    },
  },
};
</script>
